import { useRef } from 'react';

const UploadFile = (props) => {
  const fileInputRef = useRef(null);

  const handleDropzoneClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
    
      <div className="fv-row mb-8">
        <label className="fs-6 fw-semibold mb-2">{props.label}</label>
    
        <div className="dropzone" id="kt_modal_create_ticket_attachments" onClick={handleDropzoneClick}>
         
          <div className="dz-message needsclick align-items-center">
            <IconUpLoad />
            
            <div className="ms-4">
              <h3 className="fs-5 fw-bold text-gray-900 mb-1">
                Drop files here or click to upload.
              </h3>
              <span className="fw-semibold fs-7 text-gray-400">
              Acceptable File Type (.pdf, .docx, .doc, .xlsx, .xls, .csv, .jpeg, .png)
              </span>
            </div>
            
          </div>
        </div>
       
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          multiple 
          onChange={(e) => {
          
            const files = e.target.files;
            console.log('Selected files:', files);
          }}
        />
      </div>
      {/*end::Input group*/}
    </>
  );
};

export default UploadFile;

const IconUpLoad = () => {
  return (
    <>
      {/*begin::Icon*/}
          {/*begin::Svg Icon | path: icons/duotune/files/fil010.svg*/}
          <span className="svg-icon svg-icon-3hx svg-icon-primary">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z"
                fill="currentColor"
              />
              <path
                d="M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z"
                fill="currentColor"
              />
              <path
                d="M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z"
                fill="currentColor"
              />
              <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor" />
            </svg>
          </span>
          {/*end::Svg Icon*/}
          {/*end::Icon*/}
      
    </>
  )
}

