import { ToastContainer, toast } from 'react-toastify';
//import { useNavigate } from "react-router-dom"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { encode } from '../../Service/extention_json';
const initialState = {
    msg: "",
    user: "",
    token: "",
    loading: false,
    error: "",
    status: ''
}
export const signIn = createAsyncThunk('signin', async (body) => {
    //const navigate = useNavigate()
    const encodeBody = encode(body);
    console.log(encodeBody);
    const res = await fetch("https://bedrock-api.ews-jo.com/api/login/login_staff/", {
        method: "POST",
        // headers: {
        //     'Content-Type': "application/json",
        // },
        body: encodeBody

    })
        .then((res) => {

            if (res.status === 404) { console.log(res) }
            if (res.status === 200) {
                // toast.success('Success', {
                //     position: toast.POSITION.TOP_RIGHT
                // });
                //navigate("/Home")
            }
            else if (res.status === 500) {
                // navigate("/unexpected")
            }
            else if (res.status === 304) {
                //navigate("/forbidden")
            }

        }).catch((error) => {
            <ToastContainer position="top-left" />
            toast.error('Error  !', {
                position: toast.POSITION.TOP_CENTER
            });
        })

    return await res.json()
})
const authSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        addToken: (state, action) => {
            state.token = localStorage.getItem("token")
        },
        addUser: (state, action) => {
            state.user = localStorage.getItem("user")
        },
        // logout: (state, action) => {
        //     state.token = null;
        //     localStorage.clear()
        // },
    },
    extraReducers: {
        [signIn.pending]: (state, action) => {
            state.loading = true
            state.error = null
            state.status = 'loading'
        },
        [signIn.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.token = payload.token;
            state.user = payload;
            state.status = 'success'

        },
        [signIn.rejected]: (state, { payload }) => {
            state.loading = true
            state.error = payload
            state.status = 'rejected'
        }
    }

})
export const { addToken, addUser, logout } = authSlice.actions
export default authSlice.reducer