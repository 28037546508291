import React from 'react'

const SwitchInput = () => {
  return (
    <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
      <input class="form-check-input" type="checkbox" value="" name="notifications"  />
      
    </div>

  )
}

export default SwitchInput