import React, { useState, useEffect, useRef } from 'react';
import { LayoutAdmin, Radio } from "../../index";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DownloadIcon from '@mui/icons-material/Download';

import { Alert, Card, Form, Modal, ProgressBar, Row, Badge } from 'react-bootstrap';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';


import axios from 'axios';
import config from '../../../config';
import './wizard.css';

const Wizard = () => {
  const [formData, setFormData] = useState({});
  const [dataField, setDataField] = useState([]);
  const [selectedParent, setSelectedParent] = useState('');
  const [switchHide, setSwitchHide] = useState(true);
  const [isParentFor, setIsParentFor] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [formIdPost, setFormIdPost] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [multi, setMulti] = useState([]);
  const [mulitShow, setMulitShow] = useState(false);
  const [countMulti, setCountMulti] = useState(0);
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);
  const [formCheck, setFormCheck] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleSubmit = () => {
    handleSubmitForm(formIdPost);
    setTimeout(() => {
      setShowModal(true);
    }, 3000);
  };
  const dataUser = localStorage.getItem("data_user");
  const dataObject = JSON.parse(dataUser);
  console.log(dataObject.user_data.userid)
  const supplier_id = dataObject.user_data.userid;
  const onboarding_type = 'Audit Onboarding'
  const user_type = dataObject.user_type
  const handleCheckboxChange = (event) => {
    setShowForm(event.target.checked);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(config.URL);
        const data = await response.json();
        setDataField(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleFileChange = (e, fieldId) => {
    const file = e.target.files[0];
    setFileData(prevFileData => ({ ...prevFileData, [fieldId]: file }));

    const fileNameDisplay = document.getElementById("fileNameDisplay");
    if (file) {
      fileNameDisplay.textContent = file.name;
    } else {
      fileNameDisplay.textContent = "No file chosen";
    }
  };
  console.log(isParentFor)
  const handleSubmitMulti = async (formIdPost, mergedArray) => {
    try {
      const formdata = new FormData();
      formdata.append("supplier_id", `${supplier_id}`);
      formdata.append("user_type", `${user_type}`);
      formdata.append("form_id", `${formIdPost.id}`);
      const formDataArray = Object.entries(mergedArray).map(([fieldId, value]) => ({
        field_id: fieldId,
        value: value
      }));
      formdata.append("form_data", JSON.stringify(formDataArray));
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      const response = await axios.post(`${config.URL}`, formdata, {
        headers: headers
      });
      setFormData({});
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmitForm = async (formIdPost) => {
    try {
      const newArray = [...formCheck, formIdPost.name];
      setFormCheck(newArray);
      const formdata = new FormData();
      formdata.append("supplier_id", `${supplier_id}`);
      formdata.append("user_type", `${user_type}`);
      formdata.append("form_id", `${formIdPost.id}`);
      const formDataArray = Object.entries(formData).map(([fieldId, value]) => ({
        field_id: fieldId,
        value: value
      }));
      formdata.append("form_data", JSON.stringify(formDataArray));
      const fileDataArray = Object.entries(fileData).map(([fieldName, file]) => ({
        fieldName: fieldName,
        file: file
      }));
      fileDataArray.forEach(({ fieldName, file }) => {
        formdata.append(`file[${fieldName}]`, file);
      });
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      const response = await axios.post(`${config.URL}`, formdata, {
        headers: headers
      });
      setFormData({});
      console.log(response.data);
      window.Swal.fire2({
        text: "Form submitted successfully!",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: { confirmButton: "btn btn-primary" },
      });
      setMulti([])
    } catch (error) {
      console.log(error);
      window.Swal.fire2({
        text: "Sorry, there was an error submitting the form. Please try again.",
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: { confirmButton: "btn btn-primary" },
      });
    }
  };
  const handleFieldClick = (formId) => {
    setFormIdPost(formId);
    console.log(formId);
  };
  const handleButtonClick = () => {
    let hasErrors = false;
    const formIndex = currentStep - 1;
    const form = onboarding.forms[formIndex];
    form.fields.forEach((field) => {
      if (field.required && (!formData[field.id] || formData[field.id].trim() === '')) {
        setErrors((prevState) => ({
          ...prevState,
          [field.id]: `${field.name} is required.`,
        }));
        window.Swal.fire2({
          text: "Please correct the errors in the form.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          showCloseButton: true,
          customClass: {
            confirmButton: "btn btn-primary",
            closeButton: "btn btn-primary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            hasErrors = true;
            handleNext();
          }
        });
        hasErrors = true;
      } else {
        setErrors((prevState) => ({
          ...prevState,
          [field.id]: '',
        }));
        handleSubmitForm(formIdPost);
        setSelectedParent('');
        handleNext();
      }
    });
    if (!hasErrors) {
      handleSubmitForm(formIdPost);
      handleNext();
    }
  };
  const getParnId = (isParentFor, field) => {
    if (switchHide) {
      if (isParentFor.includes(field.id)) {
        return 'none';
      }
    } else if (!switchHide) {
      return 'block';
    }
  }; 
  const handleSelect = (form, isParentFor, selectedOptionValue) => {
    
    setSelectedParent(selectedOptionValue);
    if (isParentFor && form.fields) {
      const childField = form.fields.find((field) => field.id === isParentFor);
      if (childField) {
        childField.display = selectedOptionValue === childField.display_when ? 'block' : 'none';
      }
      setIsParentFor((prevIds) => prevIds.filter((id) => id !== isParentFor));
    } else {
      setSelectedParent('');
    }
    setFormData((prevData) => ({
      ...prevData,
      [`field_${isParentFor}`]: '',
    }));
  };
  const saveIsParentFor = (form) =>
  form.fields
    ? form.fields.reduce((acc, field) => {
        if (field.is_paren_for) {
          acc.push(field.is_paren_for);
        }
        return acc;
      }, [])
    : [];

    useEffect(() => {
      if (dataField) {
        const forms = dataField.flatMap((onboarding) => (onboarding.forms ? onboarding.forms : []));
        setIsParentFor(forms.flatMap(saveIsParentFor));
        setSelectedParent(''); 
      }
    }, [dataField]);
const renderFormSelect = (form, field) => {
  const { id, options, required, default_value, is_paren_for } = field;
  const selectOptions = options || [];

  return (
    <Form.Select
      type="select"
      name={`field_${id}`}
      id={id}
      className="form-select form-select-solid fw-bold"
      style={{ display: getParnId(isParentFor, field) }}
      required={required}
      defaultValue={default_value}
      onChange={(e) => handleSelect(form, is_paren_for, e.target.value)}
    >
      <option value="">Select an option</option>
      {renderSelectOptions(selectOptions, selectedParent)}
    </Form.Select>
  );
};

const renderSelectOptions = (options, selectedParent) =>
  options.map((option) => (
    <option
      key={option.id}
      value={option.option_value}
      style={{ display: option.display_when === selectedParent ? 'block' : 'none' }}
    >
      {option.option_value}
    </option>
  ));
  // end select 
  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };
  const handleNext = () => {
    setMulti([])
    const onboarding = dataField.find(
      (onboarding) =>
        onboarding.onboarding_name === onboarding_type && Array.isArray(onboarding.forms) && onboarding.forms.length > 0
    );
    if (onboarding && currentStep < onboarding.forms.length) {
      setCurrentStep(currentStep + 1);
    }
  };
  const onboarding = dataField.find(
    (onboarding) =>
      onboarding.onboarding_name === onboarding_type && Array.isArray(onboarding.forms) && onboarding.forms.length > 0
  );
  if (!onboarding) {
    return null;
  }
  const { forms } = onboarding;
  const totalSteps = forms.length;
  const handleSaveButton = (form) => {
    const dataWithSameMulti = form.fields
      .filter((field) => field.multi !== null)
      .map((field) => ({
        name: field.name,
        value: formData[field.id] !== undefined ? formData[field.id] : '',
      }));
    const multiValue = dataWithSameMulti.reduce((acc, field) => {
      if (field.value !== '') {
        const entry = { [field.name]: field.value };
        return [...acc, entry];
      }
      return acc;
    }, []);
    setMulti((prevMulti) => [...prevMulti, multiValue]);
    setMulitShow(true);
    setCountMulti(countMulti + 1);
    const mergedArray = multi.flat().reduce((merged, item) => {
      const key = Object.keys(item)[0];
      if (merged[key]) {
        merged[key] += `,,${item[key]}`;
      } else {
        merged[key] = item[key];
      }
      return merged;
    }, {});
    const saveData = Object.entries(mergedArray).map(([fieldId, value]) => ({
      field_id: fieldId,
      value,
    }));
    handleSubmitMulti(formIdPost, saveData);
  };
  const handleDownload = () => {
    window.open(document.getElementById('fileDownload'));
  };
  const handleDelete = (fieldName) => {
    setMulti((prevMulti) => {
      const updatedMulti = { ...prevMulti };
      delete updatedMulti[fieldName];
      return Object.values(updatedMulti);
    });
  };
  const handleDropzoneClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  // const totalSteps = 6;
  return (
    <LayoutAdmin title="Wizard" pages={['Home', 'Wizard']}>
      <div id="kt_app_content" className="app-content px-0">
        {dataField.map((onboarding, index) => {
          if (onboarding_type === onboarding.onboarding_name) {
            return (
              <div
                className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid gap-10"
                id="kt_create_account_stepper"
                key={index}
              >
                <div className="card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px">
                  <div className="card-body px-6 px-lg-10 px-xxl-15 py-20">
                    <div className="stepper-nav">
                      {Array.isArray(onboarding.forms) &&
                        onboarding.forms.map((form, formIndex) => (
                          <div>  <div
                            className={`stepper-item ${currentStep === formIndex + 1 ? 'current' : ''}`}
                            data-kt-stepper-element="nav"
                            key={form.id}
                            onClick={() => setCurrentStep(formIndex + 1)}
                          >
                            <div className="stepper-wrapper">
                              <div className="stepper-icon w-40px h-50px">
                                {currentStep > formIndex + 1 ? (
                                  <i className="stepper-check fas fa-check" />
                                ) : (
                                  <span className="stepper-number">
                                    {formIndex + 1}
                                  </span>
                                )}
                              </div>
                              <div className="stepper-label">
                                <h3 className="stepper-title">{form.name}</h3>
                                <div className="stepper-desc fw-semibold">
                                  {form.default_value}
                                </div>
                              </div>
                            </div>
                            <div className="stepper-line h-40px" />
                          </div>
                            <div>
                              <Modal show={showModal} onHide={handleClose} dialogClassName="my-modal" style={{ Width: '100%' }}>
                                <Modal.Header closeButton>
                                  <Modal.Title>Completion Status</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className='text-start'>
                                  <table className="table table-borderless table-hover">
                                    <thead className='text-bold' style={{ fontSize: '22px' }}>
                                      <tr>
                                        <th scope="col"><strong>Step Category</strong></th>
                                        <th scope="col" className="text-right"><strong>Status / Required</strong></th>
                                      </tr>
                                    </thead>
                                    <tbody style={{ fontSize: '18px' }}>
                                      {Array.isArray(onboarding.forms) &&
                                        onboarding.forms.map((form, formIndex) => (
                                          <tr key={formIndex}>
                                            <td>{form.name}</td>
                                            <td className="text-right">
                                              <div className="d-flex align-items-center ">
                                                {formCheck.includes(form.name) ? (
                                                  <>
                                                    <div className="mr-2">
                                                      <Badge pill bg="success">
                                                        <CheckIcon />
                                                        <p className="font-weight-bolder completed-item mb-0">Yes</p>
                                                      </Badge>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="mr-2">
                                                      <span bg="warning" className='text-warning' variant="warning"> <WarningAmberIcon bg="warning" variant="warning" /></span>
                                                      <Badge pill bg="danger">
                                                        <CloseIcon />
                                                        <p className="font-weight-bolder uncompleted-item mb-0">No</p>
                                                      </Badge>
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </Modal.Body>     </Modal>
                            </div>
                          </div>
                        )
                        )
                      }
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column flex-lg-row-fluid p-2 m-2">
                  <div className="card card-custom card-shadowless rounded-top-0 p-3 w-100">
                    <div className="card-body p-0">
                      <div className="row justify-content-center pt-lg-20 py-10">
                        <div className="col-xl-12 col-xxl-10">
                          {Array.isArray(onboarding.forms) &&
                            onboarding.forms.map((form, formIndex) => (
                              <div
                                key={form.id}
                                style={{
                                  display:
                                    currentStep === formIndex + 1 ? 'block' : 'none',
                                }}
                              >
                                <Card className='mb-20 border-none' border="none"  >
                                  <Card.Header>
                                    <Card.Title className="text-dark text-capitalize font-weight-bold mb-5">
                                      {form.name}
                                    </Card.Title>
                                  </Card.Header>
                                  {form.message && (
                                    <Card.Body>
                                      <Alert variant="warning" className="outlined-warning aletr-icon ">
                                        <WarningAmberIcon />
                                        <strong className="fs-3">{form.message}</strong>
                                      </Alert>
                                    </Card.Body>
                                  )}
                                </Card>
                                {form.after_check !== null && (
                                  <Form.Check
                                    type="checkbox"
                                    label={form.after_check}
                                    onChange={handleCheckboxChange}
                                    checked={showForm}
                                    style={{
                                      borderRadius: '5px',
                                    }}
                                    className="mb-2 pb-10"
                                  />
                                )}
                                {form.after_check === null || showForm ? (
                                  <>
                                    {Array.isArray(form.fields) &&
                                      form.fields
                                        .slice()
                                        .sort((a, b) => a.field_order - b.field_order)
                                        .map((field) => (
                                          <Row
                                            key={field.id}
                                            className="align-items-center mb-6"
                                            onClick={() =>
                                              handleFieldClick(form)
                                            }
                                          >
                                            <Form.Label
                                              className={`col-lg-2 col-form-label  text-capitalize d-flex align-items-center  fs-6 my-2 text-captalize d-${getParnId(
                                                isParentFor,
                                                field
                                              )}`}
                                              htmlFor={field.id}
                                              autoCapitalize=""
                                            >
                                              {field.name}
                                            </Form.Label>
                                            <div className="col-lg-6">
                                              {field.type === 'input' && (
                                                <Form.Group key={field.id} >
                                                  <Form.Control
                                                    type="text"
                                                    name={field.id}
                                                    value={formData[field.id] || ''}
                                                    onChange={handleChange}
                                                    isInvalid={errors[field.id]}
                                                    style={{ display: isParentFor.includes(field.id) ? 'none' : 'block' }}
                                                    placeholder={`Enter ${field.name}`}
                                                    id={field.id}
                                                    required={field.required}
                                                    defaultValue={field.default_value}
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    {errors[field.id]}
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                              )}
                                              {field.type === 'radio' && (
                                                <Form.Group key={field.id} >
                                                  {field.options.length > 1 ? (
                                                    field.options.map((option) => (
                                                      <Radio
                                                        key={option.id}
                                                        defaultValue={option.default_value}
                                                        id={option.id}
                                                        value={formData[field.id] || ''}
                                                        name={field.id}
                                                        title={option.option_value}
                                                        label={option.option_value}
                                                        onChange={handleChange}
                                                      />
                                                    ))
                                                  ) : (
                                                    <Radio
                                                      defaultValue={field.options[0].default_value}
                                                      id={field.options[0].id}
                                                      name={field.id}
                                                      title={field.options[0].option_value}
                                                      onChange={handleChange}
                                                    />
                                                  )}
                                                  <Form.Control.Feedback type="invalid">
                                                    {errors[field.id]}
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                              )}
                                              {field.type === 'file' && (

                                                <div className={`fv-row mb-8 `}>
                                                  <div>
                                                    <label className="fs-6 fw-semibold mb-2">File Upload</label>
                                                    <div className="dropzone" id={`kt_modal_create_ticket_attachments_${field.id}`} onClick={handleDropzoneClick}>
                                                      <div className="dz-message needsclick align-items-center">
                                                        <IconUpLoad />
                                                        <div className="ms-4">
                                                          <h3 className="fs-5 fw-bold text-gray-900 mb-1">
                                                            Drop files here or click to upload.
                                                          </h3>
                                                          <span className="fw-semibold fs-7 text-gray-400">
                                                            Acceptable File Type (.pdf, .docx, .doc, .xlsx, .xls, .csv, .jpeg, .png)
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <input
                                                      type="file"
                                                      ref={fileInputRef}
                                                      style={{ display: 'none' }}
                                                      value={formData[field.id] || ''}
                                                      className="hidden-file-input"
                                                      multiple
                                                      onChange={(e) => handleFileChange(e, field.id)}
                                                    />
                                                    <span id="fileNameDisplay" className='form-control bg-light mt-2'>No file chosen</span>
                                                  </div>
                                                  <Form.Control.Feedback type="invalid">
                                                    {errors[field.id]}
                                                  </Form.Control.Feedback>
                                                </div>
                                              )}
                                              {field.type === 'downloadfile' && (
                                                <Form.Group key={field.id} >
                                                  <a href={field.path} onClick={handleDownload} download>
                                                    <DownloadIcon />
                                                    Download File
                                                  </a>
                                                  <Form.Control.Feedback type="invalid">
                                                    {errors[field.id]}
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                              )}
                                              {field.type === 'select' && renderFormSelect(form, field)}
                                              {field.type === 'multiselect' && (
                                                <Form.Group key={field.id} >
                                                  <Form.Select
                                                    type='multiselect'
                                                    name={field.id}
                                                    value={formData[field.id] || ''}
                                                    className='form-select form-select-solid fw-bold'
                                                    required={field.required}
                                                    defaultValue={field.default_value}
                                                    onChange={handleChange}

                                                  >
                                                    <option value="">Select an option</option>
                                                    {field.options && field.options.map((option) => (
                                                      <option key={option.id} value={option.option_value}>
                                                        {option.option_value}
                                                      </option>
                                                    ))}
                                                  </Form.Select>
                                                  <Form.Control.Feedback type="invalid">
                                                    {errors[field.id]}
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                              )}
                                              {field.type === 'number' && (
                                                <Form.Group key={field.id} >
                                                  <Form.Control
                                                    type="number"
                                                    name={field.id}
                                                    value={formData[field.id] || ''}
                                                    onChange={handleChange}
                                                    style={{ display: isParentFor.includes(field.id) ? 'none' : 'block' }}
                                                    isInvalid={!!errors[field.id]}
                                                    placeholder={`Enter ${field.name}`}
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    {errors[field.id]}
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                              )}
                                              {field.type === 'email' && (
                                                <Form.Group key={field.id} >
                                                  <Form.Control
                                                    type="email"
                                                    name={field.id}
                                                    value={formData[field.id] || ''}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors[field.id]}
                                                    placeholder={`Enter ${field.name}`}
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    {errors[field.id]}
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                              )}
                                              {field.type === 'password' && (
                                                <Form.Group key={field.id} >
                                                  <Form.Control
                                                    type="password"
                                                    name={field.id}
                                                    value={formData[field.id] || ''}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors[field.id]}
                                                    placeholder={`Enter ${field.name}`}
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    {errors[field.id]}
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                              )}
                                              {field.type === 'textarea' && (
                                                <Form.Group key={field.id} >
                                                  <Form.Control
                                                    as="textarea"
                                                    name={field.id}
                                                    value={formData[field.id] || ''}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors[field.id]}
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    {errors[field.id]}
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                              )}
                                              {field.type === 'checkbox' && (
                                                <Form.Group key={field.id} >
                                                  {field.options.length > 1 ? (
                                                    field.options.map((option) => (
                                                      <Form.Check
                                                        key={option.id}
                                                        type="checkbox"
                                                        value={formData[field.id] || ''}
                                                        id={option.id}
                                                        name={field.id}
                                                        label={option.option_value}
                                                        onChange={handleChange}
                                                      />
                                                    ))
                                                  ) : (
                                                    <Form.Check
                                                      type="checkbox"
                                                      id={field.options[0].id}
                                                      name={field.id}
                                                      value={formData[field.id] || ''}
                                                      label={field.options[0].option_value}
                                                      onChange={handleChange}
                                                    />
                                                  )}
                                                  <Form.Control.Feedback type="invalid">
                                                    {errors[field.id]}
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                              )}
                                              {(field.type === 'date' || field.type === 'date_picker') && (
                                                <Form.Group key={field.id} >
                                                  <Form.Control
                                                    type="date"
                                                    name={field.id}
                                                    value={formData[field.id] || ''}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors[field.id]}
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    {errors[field.id]}
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                              )}
                                              {field.type === 'tel' && (
                                                <Form.Group key={field.id} >
                                                  <Form.Control
                                                    type="tel"
                                                    name={field.id}
                                                    value={formData[field.id] || ''}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors[field.id]}
                                                    placeholder={`Enter ${field.name}`}
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    {errors[field.id]}
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                              )}
                                              <div>
                                              </div>
                                            </div>
                                          </Row>
                                        ))}
                                  </>
                                ) : null}
                                {mulitShow && Object.keys(multi).length > 0 && (
                                  <div className="card">
                                    <div className="card-body">
                                      <div className="col-lg-12 ">
                                        <div className="ml-2 mt-2  ">
                                          <div className="row">
                                            <div className="col d-flex  ">
                                              {Object.entries(multi).map(([fieldName, valueArray]) => (
                                                <div key={fieldName} className='pe-5 d-flex'>
                                                  <div className="col-xl-1 col-md-1 col-sm-1 mx-5 text-danger cursor-pointer">
                                                    <DeleteForeverIcon className="color-danger" onClick={() => handleDelete(fieldName)} />
                                                  </div>
                                                  <div>
                                                    {Array.isArray(valueArray) &&
                                                      valueArray.map((item, index) => (
                                                        <div key={index}>
                                                          {Object.entries(item).map(([key, value]) => (
                                                            <p className="mb-0" key={key}>
                                                              <strong className="text-muted">{key}: </strong>
                                                              {value}
                                                            </p>
                                                          ))}
                                                        </div>
                                                      ))}</div>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {form.fields.some((field) => field.multi !== null) && (
                                  <div className="text-center py-5">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={() => handleSaveButton(form)}
                                    >
                                      Save {form.name}
                                    </button>
                                  </div>
                                )}
                              </div>
                            ))}
                        </div>
                      </div>
                      {/* begin::Footer */}
                      <div className="d-flex flex-stack p-10">
                        {currentStep > 1 && (
                          <div className="mr-2">
                            <button
                              type="button"
                              className="btn btn-lg btn-light-primary me-3"
                              data-kt-stepper-action="back"
                              onClick={handlePrevious}
                            >
                              Previous
                              <span className="me-1">
                                <ArrowBackIcon />
                              </span>
                            </button>
                          </div>
                        )}
                        {currentStep === totalSteps && (
                          <div>
                            <button
                              type="button"
                              className="btn btn-lg btn-primary me-3"
                              onClick={handleSubmit}
                            >
                              <span className="indicator-label">
                                Submit
                                <span className="ms-2 me-0">
                                  <KeyboardArrowUpIcon />
                                </span>
                              </span>
                            </button>
                          </div>
                        )}
                        {currentStep < totalSteps && (
                          <div>
                            <button
                              type="button"
                              className="btn btn-lg btn-primary"
                              data-kt-stepper-action="next"
                              onClick={handleButtonClick}
                            >
                              Continue
                              <span className="ms-1 me-0">
                                <ArrowForwardIcon />
                              </span>
                            </button>
                          </div>
                        )}
                      </div>
                      {/* End::Footer */}
                      <div className="p-5">
                        <ProgressBar variant="success" now={(currentStep / totalSteps) * 100} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </LayoutAdmin>
  );
};
export default Wizard;
const IconUpLoad = () => {
  return (
    <>
      {/*begin::Icon*/}
      {/*begin::Svg Icon | path: icons/duotune/files/fil010.svg*/}
      <span className="svg-icon svg-icon-3hx svg-icon-primary">
        <svg
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.3"
            d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z"
            fill="currentColor"
          />
          <path
            d="M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z"
            fill="currentColor"
          />
          <path
            d="M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z"
            fill="currentColor"
          />
          <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor" />
        </svg>
      </span>
      {/*end::Svg Icon*/}
      {/*end::Icon*/}

    </>
  )
}