import React, { useState } from "react";
import Footer from "../../signin/SignIn/split/Footer";
import Content from "../../signin/SignIn/split/Content";
import { useNavigate } from "react-router-dom";

const SignInMethod = () => {
  const emailRest = localStorage.getItem("email");
  const [newPassword, setNewPassword] = useState("");
  const [email, setEmail] = useState(emailRest);

  const navigate = useNavigate();
  const reset_password_url = localStorage.getItem("reset_password_url");

  const resetPasswordInvite = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Cookie", "sp_session=vanaanvpd89ouqd1qo1e34n0tar5s8m2");

    const formdata = new FormData();
    formdata.append("email", emailRest);
    formdata.append("password", newPassword);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    try {
      const response = await fetch(reset_password_url, requestOptions);
      const result = await response.text();
      console.log(result);
      window.Swal.fire2({
        text: "Password reset successful!",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: { confirmButton: "btn btn-primary" },
      });
      navigate("/", { replace: true });
      setNewPassword("");
    } catch (error) {
      console.log('Error resetting password:', error);
    }
  };
  return (
    <>
      <div className="d-flex flex-column flex-root mt-20" id="kt_app_root">
        <style
          dangerouslySetInnerHTML={{
            __html: `
              body {
                background-image: url(${process.env.PUBLIC_URL + "/assets/media/auth/bg10.jpeg"});
              }
              [data-bs-theme="dark"] body {
                background-image: url(${process.env.PUBLIC_URL + "/assets/media/auth/bg10-dark.jpeg"});
              }
            `,
          }}
        />
        <div className="d-flex flex-column flex-lg-row flex-column-fluid justify-content-lg-end">
          <div className="d-flex flex-lg-row-fluid">
            <Content />
          </div>
          <div className="d-flex flex-column-fluid  p-20 p-md-15">
            <div className="bg-body  flex-center rounded-4 w-md-800px p-10">
              <div className="d-flex flex-center flex-column align-items-stretch h-lg-150">
                <>
                  <div className="card mb-5 mb-xl-10">
                    <div
                      className="card-header border-0 cursor-pointer"
                      role="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_account_signin_method"
                    >
                      <div className="card-title m-0">
                        <h3 className="fw-bold m-0">Sign-in Method</h3>
                      </div>
                    </div>
                    <div
                      id="kt_account_settings_signin_method"
                      className="collapse show"
                    >
                      <div className="card-body border-top p-9">
                        <ChangePassword
                          newPassword={newPassword}
                          setNewPassword={setNewPassword}
                          resetPasswordInvite={resetPasswordInvite}
                          email={email}
                        />
                      </div>
                    </div>
                  </div>
                </>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const ChangePassword = ({ email, newPassword, setNewPassword, resetPasswordInvite }) => {
  const [confirmPassword, setConfirmPassword] = useState("");
  const handlePasswordChange = () => {
    if (newPassword !== confirmPassword) {
      console.log("Passwords do not match");
      return;
    }
    resetPasswordInvite();
  };
  return (
    <>
      <div>
        <div className="mb-10">
          <div className="row mb-6">
            <div className="col-lg-12 ">
              <div className="">
                <label
                  htmlFor="emailaddress"
                  className="form-label fs-6 fw-bold mb-3"
                >
                  Enter New Email Address
                </label>
                <input
                  type="email"
                  disabled
                  className="form-control form-control-lg form-control-solid"
                  id="emailaddress"
                  placeholder="Email Address"
                  name="emailaddress"
                  defaultValue={email}
                />
              </div>
            </div>
          </div>
        </div>
        <div id="kt_signin_password_edit">
          <form id="kt_signin_change_password" className="form" noValidate="novalidate">
            <div className="row mb-1">
              <div className="col-lg-6">
                <div className="fv-row mb-0">
                  <label
                    htmlFor="newpassword"
                    className="form-label fs-6 fw-bold mb-3"
                  >
                    New Password
                  </label>
                  <input
                    type="password"
                    className="form-control form-control-lg form-control-solid"
                    name="newpassword"
                    id="newpassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="fv-row mb-0">
                  <label
                    htmlFor="confirmpassword"
                    className="form-label fs-6 fw-bold mb-3"
                  >
                    Confirm New Password
                  </label>
                  <input
                    type="password"
                    className="form-control form-control-lg form-control-solid"
                    name="confirmpassword"
                    id="confirmpassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="form-text mb-5">
              Password must be at least 8 characters and contain symbols
            </div>
            <div className="d-flex">
              <button
                id="kt_password_submit"
                type="button"
                className="btn btn-primary me-2 px-6"
                onClick={handlePasswordChange}
              >
                Update Password
              </button>
              <button
                id="kt_password_cancel"
                type="button"
                className="btn btn-color-gray-400 btn-active-light-primary px-6"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SignInMethod;
