import { configureStore } from "@reduxjs/toolkit";
import {
  AuthReducer,
  OnbordingSlice,
  PreventSlice,
  RecordsSlice,
  VendorSlice,StatementSlice
} from "./index";
import wizardReducer from './Slices/wizardSlice';
import DataCleanseSettingSlice from "./Slices/Data-Cleanse-setting-Slice";


const store = configureStore({
  reducer: {
    records: RecordsSlice,
    user: AuthReducer,
    onbording: OnbordingSlice,
    statement:StatementSlice,
    prevent:PreventSlice,
    vendor: VendorSlice,
    setting: DataCleanseSettingSlice,
   
      wizard: wizardReducer,
    
  },
});

export default store;
