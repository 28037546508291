import { createHashRouter } from "react-router-dom";
import App from "../App";
import ErrorPage from "./error-page";
import {
  ActivityLog,
  Announcements,
  Claims,
  ContactsVendorPage,
  CreateClaims,
  DataFinal,
  EditTabOnborading,
  ExportExecl,
  Forbidden,
  GlobalVerifiction,
  HomePage,
  ImportSupplier,
  OnboradingDataCleanse,
  OriginalVendorMaster,
  Profile,
  ReadyToDataCleans,
  Report,
  SendEmailTemplatePage,
  Setting,
  SetupCurrencies,
  SetupEmailTemplates,
  SetupFeaturesEnable,
  SetupGDPR,
  SetupRoles,
  SetupSettings,
  SetupStaff,
  SignIn,
  restPassword,
  TotalApprovedVendor,
  TotalAssigneeVendor,
  TotalNotApprovedVendor,
  Unexpected,
  VendorArchive,
  VendorInvitation,
  VendorPage,
  Wizard,
  CollectedStatement,
  OutstandingStatement,
  UploadStatement,
  RequsetHelp,
  DetailsVendor,
  ProfileContent,
  StatementVendorTabs,
  ClaimsVendorTabs,
  FilesVendorTabs,
  MapsVendorTabs,
  NoteVendorTab,
  ContactVendorTabs,
  LayoutVendor,
  VendorHome,
  ProfileContentPage,
  ContactPortalTabs,
  StatementPortalTab,
  ClaimsPortalTabs,
  OverviewAudit,
  OnboradingPortal,
  DetailsStatement,
  OverviewStatementTab,
  FilesStatementTabs,
  DiscussionsStatementTabs,
  DiscussionsStatementDetailsTabs,
  ClaimsStatementTabs,
  NoteStetmentTab,
  ActivitiesDiscussionTab,
  BedrockVerifiction,
  ApprovalList,
  SupplierInvite,
  OverviewCleanse,
  OverviewVerify,
  OverviewInView,
  OverviewPrevent,
  ClaimViewVendor,
  BedrockOnboarding,
  BankVerification,
  OverViewStatement,
  OverViewClaims,
  OverViewVendor,
  SignInMethod
  

} from "../component/index";

import { UploadPrevent } from "../component/pages/prevent/pages-prevent";
import OverviewDataCleanse from "../component/pages/cleanse/component-cleanse/OverviewDataCleanse";
import OverviewSupplierInvitation from "../component/pages/cleanse/component-cleanse/OverviewSupplierInvitation";
import OverviewBedrockVerification from "../component/pages/cleanse/component-cleanse/OverviewBedrockVerification";

export const router = createHashRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    mode: "absolute",
    children: [
      { path: "/", element: <SignIn /> },
      {
        path: "/ExportExecl",
        element: <ExportExecl />,
      },
    
      {
        path: "/signInMethod",
        element: <SignInMethod />,
      },
    
      {
        path: "/unexpected",
        element: <Unexpected />,
      },
      {
        path: "/forbidden",
        element: <Forbidden />,
      },
      {
        path: "/wizard",
        element: <Wizard />,
      },
      {
        path: "/home",
        element: <HomePage />,
      },
    
      {
        path: "/profile",
        element: <Profile />,
      },
      //////////////data cleans Start
      { path: "/cleanse_overview", element: <OverviewCleanse /> },
      { path: "/data_cleanse_overview", element: <OverviewDataCleanse /> },
      { path: "/supplier_invite_cleanse_overview", element: <OverviewSupplierInvitation /> },
      { path: "/verification_cleanse_overview", element: <OverviewBedrockVerification /> },
      {
        path: "/setting",
        element: <Setting />,
      },
      {
        path: "/original_vendor",
        element: <OriginalVendorMaster />,
      },
      {
        path: "/vendor_archive",
        element: <VendorArchive />,
      },
      {
        path: "/vendor_invitation",
        element: <VendorInvitation />,
      },
      {
        path: "/vendor_invitation_onborading/:oboradingId",
        element: <OnboradingDataCleanse />,
      },
      {
        path: "/edit_onborading/:tabId/:cleanse",
        element: <EditTabOnborading />,
      },
      {
        path: "/data_final",
        element: <DataFinal />,
      },
      {
        path: "/ready_to_data_cleans",
        element: <ReadyToDataCleans />,
      },
      {
        path: "/report",
        element: <Report />,
      },
      { path: "/total_approved_vendor", element: <TotalApprovedVendor /> },
      { path: "/total_assignee_vendor", element: <TotalAssigneeVendor /> },
      {
        path: "/total_Not_approved_vendor",
        element: <TotalNotApprovedVendor />,
      },
      { path: "/import_supplier", element: <ImportSupplier /> },
      
          //////////////data cleans end
      //////////////setup
      { path: "/staff_setup", element: <SetupStaff /> },
      { path: "/currencies_setup", element: <SetupCurrencies /> },
      { path: "/email_templates_setup", element: <SetupEmailTemplates /> },
      {
        path: "/send_email_templates_setup",
        element: <SendEmailTemplatePage />,
      },
      { path: "/setting_setup", element: <SetupSettings /> },
      { path: "/roles_setup", element: <SetupRoles /> },
      { path: "/features_enable_setup", element: <SetupFeaturesEnable /> },
      { path: "/gdpr_setup", element: <SetupGDPR /> },
      { path: "/onboarding_setup", element: <BedrockOnboarding /> },
  
      ///////bedrock verifiction
      { path: "/bedrock_verifiction", element: <BedrockVerifiction /> },
      { path: "/bank_verification", element: <BankVerification /> },
      { path: "/global_verification", element: <GlobalVerifiction /> },
      ///////Statement
      { path: "/upload_statements", element: <UploadStatement /> },
      { path: "/outstanding_statements", element: <OutstandingStatement /> },
      { path: "/collected_statements", element: <CollectedStatement /> },
      {
        element: <DetailsStatement />,
        children: [
          {
            path: "/statement_overview_page",
            element: <OverviewStatementTab />,
          },
          { path: "/statement_files_page", element: <FilesStatementTabs /> },
          {
            path: "/statement_discussions_page",
            element: <DiscussionsStatementTabs />,
          },
          {
            path: "/statement_discussions_details_page",
            element: <DiscussionsStatementDetailsTabs />,
          },
          { path: "/statement_claims_page", element: <ClaimsStatementTabs /> },
          { path: "/statement_nots_page", element: <NoteStetmentTab /> },
          {
            path: "/statement_activity_page",
            element: <ActivitiesDiscussionTab />,
          },
        ],
      },
      /////// utilities
      { path: "/announcements_utilities", element: <Announcements /> },
      { path: "/activity_log_utilities", element: <ActivityLog /> },
      /////// Claims
      { path: "/all_Claims", element: <Claims /> },
      { path: "/new_claims", element: <CreateClaims /> },
      /////// supplier_invition
      { path: "/supplier_invite_si", element: <SupplierInvite /> },
      { path: "/approval_list_si", element: <ApprovalList /> },
      /////// Requset Help
      { path: "/request_help", element: <RequsetHelp /> },
      ////vendor
      { path: "/vendor_page", element: <VendorPage /> },
      { path: "/vendor_contact_page", element: <ContactsVendorPage /> },

      {
        element: <DetailsVendor />,
        children: [
          {
            path: "/vendor_view_admin_profile_page",
            element: <ProfileContent />,
          },
          { path: "/vendor_contacts_page", element: <ContactVendorTabs /> },
          { path: "/vendor_nots_page", element: <NoteVendorTab /> },
          { path: "/vendor_statements_page", element: <StatementVendorTabs /> },
          { path: "/vendor_claims_page", element: <ClaimsVendorTabs /> },
          { path: "/vendor_files_page", element: <FilesVendorTabs /> },
          { path: "/vendor_maps_page", element: <MapsVendorTabs /> },
        ],
      },
      { path: "/test", element: <DetailsStatement /> },

      ///portal route start
      {
        element: <LayoutVendor />,
        children: [
          { path: "/vendor_layout_page", element: <VendorHome /> },
          {
            path: "/vendor_my_contacts_page",
            element: <ContactPortalTabs isViewVendor={true} />,
          },
          {
            path: "/vendor_my_statements_page",
            element: <StatementPortalTab isViewVendor={true} />,
          },
          {
            path: "/vendor_my_claims_page",
            element: <ClaimsPortalTabs isViewVendor={true} />,
          },
          {
            path: "/help-request-vendor",
            element: <ClaimsPortalTabs isViewVendor={true} />,
          },
          {
            path: "/knowledge-base-vendor",
            element: <ClaimViewVendor isViewVendor={true} />,
          },
          {
            path: "/my-help-request-vendor",
            element: <ClaimsPortalTabs isViewVendor={true} />,
          },
          {
            path: "/vendor_my_profile_page",
            element: <ProfileContentPage isViewVendor={true} />,
          },

        
        ],
      },
      {
        path: "/vendor_onborad_portal_page/:oboradingId",
        element: <OnboradingPortal />,
      },
      ///portal route end

      ////Audit Start
      { path: "/audit_overview", element: <OverviewAudit /> },
  
      { path: "/verify_overview", element: <OverviewVerify /> },
      { path: "/inview_overview", element: <OverviewInView /> },
      { path: "/prevent_overview", element: <OverviewPrevent /> },
      { path: "/upload_overview/:status", element: <UploadPrevent /> },



      { path: "/vendor_claim_portal_page", element: <ClaimViewVendor /> },
      { path: "/overview_statements", element: <OverViewStatement/> },
      { path: "/overview_Claims", element: <OverViewClaims/> },
      { path: "/overview_vendor", element: <OverViewVendor/> },
    ],
  },
]);
