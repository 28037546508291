


import React from "react";

import { useNavigate } from "react-router-dom";
import { LayoutAdmin, HeaderVerify } from "../../index";

///props
//sidebar widget(option)
//children widget
///extraAction btn (option)
//title Strig
//pages array ex:[ "Bedrock Verifiction", "Bedrock Verifiction"]
const LayoutVerify = (props) => {
  const navigate = useNavigate();
  props.pages?.unshift("Verfication ");

  return (
    <LayoutAdmin
      title={props.title}
      pages={props.pages}
      extraAction={props.extraAction}
      sidebar={props.sidebar}
      menuNavigationHeader={<HeaderVerify />}
    >
      {props.children}
    </LayoutAdmin>
  );
};

export default LayoutVerify;
