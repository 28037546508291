import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CheckBox } from "../../../../index";
import axios from "axios";
import Input from "./Input";

function Form() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const form = useRef(null);
  const dispatch = useDispatch();
  const { loading, user, error, status } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const setCookie = (name, value, days) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${encodeURIComponent(value)};expires=${expires.toUTCString()};path=/`;
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  };

  const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };

  const submit = async (e) => {
    e.preventDefault();

    try {
      const myHeaders = new Headers();
      myHeaders.append("Cookie", "csrf_cookie_name=60e89dcf50f0933462336f27dc7e062e; sp_session=elm1qfk4a8dmnctepmclktja1v9vj1qq");

      const formdata = new FormData();
      formdata.append("email", email);
      formdata.append("password", password);

      const requestOptions = {
        method: 'POST',
        maxBodyLength: Infinity,
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      const response = await fetch("https://bedrock-all.doublesupport.co/api/login/login_contact", requestOptions);

      if (response) {
        const data = await response.json();
        console.log(data);
        console.log(data.user_type);
        console.log(data.reset_password_url);

        const user_type = data.user_type;
        const reset_password_url = data.reset_password_url;
        const status = data.status;
      ;

      
        setCookie('user_type', user_type, 7); 
        setCookie('data_user', data, 7);

        if (reset_password_url) {
          localStorage.setItem('user_type',JSON.stringify(user_type) );
          localStorage.setItem('data_user',JSON.stringify(data) )
          setCookie('reset_password_url', reset_password_url, 7);
          setCookie('email', email, 7);
          navigate('/signInMethod', { replace: true });
          
        } else {
          if (status === true) {
            localStorage.setItem('user_type',JSON.stringify(user_type) );
            localStorage.setItem('data_user',JSON.stringify(data) )
            navigate('/overview_statements', { replace: true });
          } else {
            window.Swal.fire2({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: !1,
              confirmButtonText: "Ok, got it!",
              customClass: { confirmButton: "btn btn-primary" },
            });
          }
        }
      } else {
        console.log('Response status:', response.status);
        window.Swal.fire2({
          text: "Sorry, looks like there are some errors detected, please try again.",
          icon: "error",
          buttonsStyling: !1,
          confirmButtonText: "Ok, got it!",
          customClass: { confirmButton: "btn btn-primary" },
        });
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };





  return (
    <div className="d-flex flex-center flex-column-fluid pb-15 pb-lg-20">
      <form
        className="form w-100"
        noValidate="novalidate"
        id="kt_sign_in_form"
        data-kt-redirect-url="/Home"
        action="#"
        ref={form}
        //onSubmit={handleSubmit(submitForm)}
        // onSubmit={onSubmit}
        onSubmit={submit}
      >
        <div className="text-center mb-11">
          <h1 className="text-dark fw-bolder mb-3">Sign In</h1>

          <div className="text-gray-500 fw-semibold fs-6">
            Welcome In Bedrock
          </div>
        </div>

        <div className="separator separator-content my-14">
          <span className="w-125px text-gray-500 fw-semibold fs-7">
            Sign In to Your Account
          </span>
        </div>

        <div className="fv-row mb-8">
          <Input
            id="email"
            type={"email"}
            placeHolder={"Email"}
            setVal={(e) => setEmail(e)}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="fv-row mb-3">
          <Input
            id="password"
            type={"password"}
            placeHolder={"Password"}
            setVal={(e) => setPassword(e)}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
          <div />

          <a
            href=""
            // href="authentication/layouts/overlay/reset-password.html"
            className="link-primary"
          >
            Forgot Password ?
          </a>
        </div>

        <div className="d-grid mb-10">
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-primary"
          // onClick={submitForm}
          >
            <span className="indicator-label">Sign In</span>

            <span className="indicator-progress">
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          </button>
        </div>

        <div className="text-gray-500 text-center fw-semibold fs-6">
          <CheckBox title="Remember Me ." isHint={true} />
        </div>
      </form>
    </div>
  );
}

export default Form;