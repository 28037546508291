import React, { useRef } from 'react'
import { useEffect } from 'react';

const TestScript = () => {
  const first = useRef(null);
  function submiteHandel() {
    var e = first.current;
    var t = function (e) {
      if (!e.id) return e.text;
      var t = document.createElement("span"),
        a = "";
      return (
        (a +=
          '<img src="' +
          e.element.getAttribute("data-kt-select2-icon") +
          '" class="rounded-circle h-20px me-2" alt="image"/>'),
        (a += e.text),
        (t.innerHTML = a),
        window.$(t)
      );
    };
    window.$(e).select2({
      placeholder: "Select coin",
      minimumResultsForSearch: 1 / 0,
      templateSelection: t,
      templateResult: t,
    });
  }

  return (
    <div className="form-floating border border-gray-300 rounded mb-7">
      <select
        ref={first}
        onMouseEnter={submiteHandel}
        className="form-select form-select-transparent"
        id="kt_forms_widget_1_select_1"
      >
        <option />
        <option
          value={0}
          data-kt-select2-icon="assets/media/svg/coins/bitcoin.svg"
          selected="selected"
        >
          Bitcoin/BTC
        </option>
        <option
          value={1}
          data-kt-select2-icon="assets/media/svg/coins/ethereum.svg"
        >
          Ethereum/ETH
        </option>
        <option
          value={2}
          data-kt-select2-icon="assets/media/svg/coins/filecoin.svg"
        >
          Filecoin/FLE
        </option>
        <option
          value={3}
          data-kt-select2-icon="assets/media/svg/coins/chainlink.svg"
        >
          Chainlink/CIN
        </option>
        <option value={4} data-kt-select2-icon="assets/media/svg/coins/binance.svg">
          Binance/BCN
        </option>
      </select>
      <label htmlFor="floatingInputValue">Coin Name</label>
    </div>

  )
}

export default TestScript
