
import { Outlet, useLocation } from "react-router-dom";
import "./App.css";
import { MasterInit } from "./_doublesupport/layout/MasterInit";
import  Loading  from "./component/core/main/Loading";
import { useEffect } from "react";
import { MenuComponent } from "./_doublesupport/assets/ts/components";


function App() {
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);
  return (
    <>
     <Loading/> 
      <Outlet />
      <MasterInit />
    </>
  );
}

export default App;




