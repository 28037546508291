import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config';

export const fetchData = createAsyncThunk('wizard/fetchData', async () => {
  try {
    const response = await axios.get(config.URL);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching data:', error);
  }
});

const wizardSlice = createSlice({
  name: 'wizard',
  initialState: {
    dataField: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.loading = false;
        state.dataField = action.payload;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default wizardSlice;