import React from 'react';

const Radio = (props) => {
  const { defaultValue = '', id, name, title, desc } = props;

  return (
    <div className={`form-check form-check-custom form-check-solid ${props.className} my-5`}>
      <input
        className="form-check-input me-3"
        name={name}
        type="radio"
        defaultValue={defaultValue}
        id={id}
      
      />
      <label className="form-check-label" htmlFor={id}>
        <div className="fw-bold text-gray-800">{title}</div>
        {desc ? <div className="text-gray-600">{desc}</div> : ''}
      </label>
    </div>
  );
};

export default Radio;
