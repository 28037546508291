import React from 'react'
//Style 
import "./signin.css"
//Compontnts
import Content from './split/Content'
import Form from './split/Form'
import Footer from './split/Footer'
import { useNavigate } from 'react-router-dom'

function SignIn() {

  return (
    <div>
      <>
        <div className="d-flex flex-column flex-root mt-20" id="kt_app_root">
          <style
            dangerouslySetInnerHTML={{
              __html:
                `body { background-image: url(${process.env.PUBLIC_URL + '/assets/media/auth/bg10.jpeg'}); } [data-bs-theme=\"dark\"] body { background-image: url(${process.env.PUBLIC_URL + '/assets/media/auth/bg10-dark.jpeg'}); } `
            }}
          />
          <div className="d-flex flex-column flex-lg-row flex-column-fluid justify-content-lg-end">

            <div className="d-flex flex-lg-row-fluid">

              <Content />

            </div>

            <div className="d-flex flex-column-fluid flex-lg-row-fluid justify-content-center justify-content-lg-center p-20 p-md-15">

              <div className="bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-10">

                <div className="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px">

                  <Form />
                  <Footer />

                </div>

              </div>

            </div>

          </div>

        </div>

      </>

    </div>
  )
}

export default SignIn
